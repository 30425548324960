import { Injectable } from '@angular/core';

import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import { CookieService } from 'ngx-cookie-service';
import { TourItemModel } from '../../guru-api/models/tour-item.model';
import { TourModel } from '../../guru-api/models/tour.model';
import { TourStopModel } from '../../guru-api/models/tour-stop.model';
import { SiteSectionModel } from '../../guru-api/models/site-section.model';

@Injectable({
  providedIn: 'root'
})
export class GuruFirebaseService {
  firebaseApp: firebase.app.App = null;
  analytics: firebase.analytics.Analytics;
  performance: firebase.performance.Performance;
  constructor(private cookieService: CookieService) {}


  public initializeFirebase(config: object): void {
    if (this.firebaseApp) {
      return;
    }
    console.log('initialize firebase');
    this.firebaseApp = firebase.initializeApp(config);
    this.analytics = firebase.analytics();
    this.performance = firebase.performance();
    const langCode = this.cookieService.get('language');
    if (langCode) {
      console.log(langCode)
      this.analytics.setUserProperties({ language: langCode });
      this.analytics.setUserProperties({ "ul": langCode})
      this.analytics.setUserProperties({"chosen_language": langCode});
      
    }
  }

  public logVisitTourItemPage(item: TourItemModel): void {
    if (!this.firebaseApp) {
      console.error('Can\'t log event, because firebase app is not initialized');
      return;
    }
    this.analytics.logEvent('Visit tour item', {
      content_type: 'tour_item',
      content_id: item.id,
      content_name: item.name,
      items: [{ name: item.name, id: item.id}]
    });
  }

  public logVisitTourPage(item: TourModel): void {
    if (!this.firebaseApp) {
      console.error('Can\'t log event, because firebase app is not initialized');
      return;
    }
    this.analytics.logEvent('Visit tour', {
      content_type: 'tour',
      content_id: item.id,
      content_name: item.name,
      items: [{ name: item.name, id: item.id}]
    });
  }

  public logVisitTourStopPage(item: TourItemModel): void {
    if (!this.firebaseApp) {
      console.error('Can\'t log event, because firebase app is not initialized');
      return;
    }
    this.analytics.logEvent('Visit tour stop', {
      content_type: 'tour_stop',
      content_id: item.id,
      content_name: item.name,
      items: [{ name: item.name, id: item.id}]
    });
  }

  public logVisitMapPage(item: SiteSectionModel): void {
    if (!this.firebaseApp) {
      console.error('Can\'t log event, because firebase app is not initialized');
      return;
    }
    this.analytics.logEvent('Visit map page', {
      content_type: 'map',
      content_id: item.id,
      content_name: item.name,
      items: [{ name: item.name, id: item.id}]
    });
  }
}
